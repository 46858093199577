<template>
  <div>
    <div class="card" :class="{ card1: customer==='qingtian' }">
      <div class="title">
        <div class="left">
          <img
            v-if="customer==='qingtian'"
            src="@/assets/images/qingtian/基本信息.png"
            alt=""
          />
          <span v-if="customer==='jinlang'"></span>&nbsp;基本信息
        </div>
      </div>
      <div class="content">
        <ul>
          <li>东西长度（m）</li>
          <li>{{ baseInfo.EastWestLength }}</li>
        </ul>
        <ul>
          <li>南北宽度（m）</li>
          <li>{{ baseInfo.NorthSouthWidth }}</li>
        </ul>
        <ul>
          <li>南坡宽度（m）</li>
          <li>{{ baseInfo.SouthSlopeWidth }}</li>
        </ul>
        <ul>
          <li>南坡坡度（°）</li>
          <li>{{ baseInfo.SouthSlope }}</li>
        </ul>
        <ul>
          <li>北坡宽度（m）</li>
          <li>{{ baseInfo.NorthSlopeWidth }}</li>
        </ul>
        <ul>
          <li>北坡坡度（°）</li>
          <li>{{ baseInfo.NorthSlope }}</li>
        </ul>
        <ul>
          <li>屋檐高度（m）</li>
          <li>{{ baseInfo.EavesHigh }}</li>
        </ul>
<!--        <ul>-->
<!--          <li>南侧挑檐宽度（CM）</li>-->
<!--          <li>{{ baseInfo.SouthOverhang }}</li>-->
<!--        </ul>-->
<!--        <ul>-->
<!--          <li>北侧挑檐宽度（CM）</li>-->
<!--          <li>{{ baseInfo.NorthOverhang }}</li>-->
<!--        </ul>-->

        <!-- ///// -->
        <ul>
          <li>房屋高度（m）</li>
          <li>{{ baseInfo.HouseHeight }}</li>
        </ul>
        <ul>
          <li>房屋朝向</li>
          <li>{{ baseInfo.HouseFacing }}</li>
        </ul>
        <ul v-if="baseInfo.HouseFacing !== '正南'">
          <li>偏向度数（°）</li>
          <li>{{ baseInfo.FacingDegree }}</li>
        </ul>
        <ul>
          <li>距并网点距离（m）</li>
          <li>{{ baseInfo.GridConnectionDistance }}</li>
        </ul>
<!--        <ul>-->
<!--          <li>上人通道位置</li>-->
<!--          <li>{{ baseInfo.AccessPosition }}</li>-->
<!--        </ul>-->
<!--        <ul>-->
<!--          <li>上人通道方式</li>-->
<!--          <li>{{ baseInfo.AccessMode }}</li>-->
<!--        </ul>-->
        <ul>
          <li>屋脊高度（m）</li>
          <li>{{ baseInfo.RidgeHeight }}</li>
        </ul>
<!--        <ul>-->
<!--          <li>是否有装饰物</li>-->
<!--          <li>{{ baseInfo.IsHaveDecorations }}</li>-->
<!--        </ul>-->
        <ul>
          <li>屋面类型</li>
          <li>{{ baseInfo.RoofingType }}</li>
        </ul>
        <ul
          v-if="baseInfo.RoofingType == '预制板' || baseInfo.RoofingType == '现浇板'"
        >
          <li>屋面厚度（m）</li>
          <li>{{ baseInfo.RoofSlabThickness }}</li>
        </ul>
        <div style="margin-bottom: 15px" v-if="baseInfo.RoofingType == '木望板'">
          <ul>
            <li>木檩直径（m）</li>
            <li>{{ baseInfo.WoodPurlinDiameter }}</li>
          </ul>
          <ul>
            <li>木檩间距（m）</li>
            <li>{{ baseInfo.WoodPurlinSpacing }}</li>
          </ul>
          <ul>
            <li>木望板厚度（m）</li>
            <li>{{ baseInfo.WoodWatchboardThickness }}</li>
          </ul>
        </div>
        <div style="margin-bottom: 15px" v-if="baseInfo.RoofingType == '檩橼结构'">
          <ul>
            <li>木檩条直径（m）</li>
            <li>{{ baseInfo.PurlinDiameter }}</li>
          </ul>
          <ul>
            <li>木檩条间距（m）</li>
            <li>{{ baseInfo.PurlinSpacing }}</li>
          </ul>
          <ul>
            <li>人字梁东西跨度（m）</li>
            <li>{{ baseInfo.HerringboneBeamEastWest }}</li>
          </ul>
          <ul>
            <li>人字梁南北跨度（m）</li>
            <li>{{ baseInfo.HerringboneBeamNorthSouth }}</li>
          </ul>
          <ul>
            <li>人字梁主梁直径（m）</li>
            <li>{{ baseInfo.HerringboneGirderDiameter }}</li>
          </ul>
        </div>
        <ul>
          <li>安装方式</li>
          <li>{{ baseInfo.installationMethods }}</li>
        </ul>
      </div>
      <div>
        <img class="canvasimg" :src="canvasImg" />
      </div>
    </div>
    <div class="btnBox">
      <div class="cancel" @click="cancelEvent">取消</div>
      <div class="addcancel" @click="save" :disabled="stopEnd">保存数据</div>
      <div class="determine" @click="submit">提交踏勘信息</div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { reactive, toRefs, getCurrentInstance } from 'vue'
import { Dialog, Toast } from 'vant'
import {AddFlatRoof, AddObliqueRoof, AddTask, GetTaskState, ProjectErrorInfo} from "@/api/api"

export default {
  setup() {
    const router = useRouter()
    let baseInfo = {}
    const temporary = JSON.parse(sessionStorage.getItem('baseInfo'));
    if (JSON.parse(sessionStorage.getItem('requestInnerRoof')).hasOwnProperty('isTrue')) {  // 基本信息再预览
      temporary.EastWestLength  = sessionStorage.getItem('inpValW') / 1000 // 东西长度
      temporary.NorthSouthWidth = sessionStorage.getItem('inpValH1') / 1000 + sessionStorage.getItem('inpValH2') /1000 // 南北宽度
      temporary.SouthSlopeWidth = sessionStorage.getItem('inpValH2') / 1000 // 南坡宽度
      temporary.NorthSlopeWidth = sessionStorage.getItem('inpValH1') / 1000 // 北坡宽度
      temporary.EavesHigh /= (10 * 100) // 屋檐高度
      // temporary.NorthSouthWidth = (JSON.parse(sessionStorage.getItem('inpValH1')) / 100) + (JSON.parse(sessionStorage.getItem('inpValH2')) / 100)
      temporary.HouseHeight /= (10 * 100) // 房屋高度
      // 房屋朝向
      if (temporary.HouseFacing === 1) {
        temporary.HouseFacing = '南偏东'
      } else if (temporary.HouseFacing === 2) {
        temporary.HouseFacing = '南偏西'
      } else if (temporary.HouseFacing === 3) {
        temporary.HouseFacing = '正南'
      }
      temporary.GridConnectionDistance /= (10 * 100) // 距并网点距离
      temporary.RidgeHeight /= (10 * 100) // 屋脊高度
      // 屋面类型
      if(temporary.RoofingType === 1) {
        temporary.RoofingType = '现浇板'
        temporary.RoofSlabThickness /= (10 * 100) // 屋面厚度
      } else if (temporary.RoofingType === 2) {
        temporary.RoofingType = '预制板'
        temporary.RoofSlabThickness /= (10 * 100) // 屋面厚度
      } else if (temporary.RoofingType === 3) {
        temporary.RoofingType = '木望板'
        temporary.WoodPurlinDiameter /= (10 * 100) // 木檩直径
        temporary.WoodPurlinSpacing /= (10 * 100) // 木檩间距
        temporary.WoodWatchboardThickness /= (10 * 100) // 木望板厚度
      } else if (temporary.RoofingType === 4) {
        temporary.RoofingType = '檩橼结构'
        temporary.PurlinDiameter /= (10 * 100) // 木檩条直径
        temporary.PurlinSpacing /= (10 * 100) // 木檩条间距
        temporary.HerringboneBeamEastWest /= (10 * 100) // 人字梁东西跨度
        temporary.HerringboneBeamNorthSouth /= (10 * 100) // 人字梁南北跨度
        temporary.HerringboneGirderDiameter /= (10 * 100) // 人字梁主梁直径
      }

      if (temporary.AccessMode === 0) { // 上人通道方式
        temporary.AccessMode = '无'
      } else if (temporary.AccessMode === 1) {
        temporary.AccessMode = '爬梯'
      } else if (temporary.AccessMode === 2) {
        temporary.AccessMode = '楼梯'
      } else if (temporary.AccessMode === 3) {
        temporary.AccessMode = '孔洞'
      } else if (temporary.AccessMode === 4) {
        temporary.AccessMode = '其他'
      }

      if (temporary.AccessPosition === 0) { // 上人通道位置
        temporary.AccessPosition = '无'
      } else if (temporary.AccessPosition === 1) {
        temporary.AccessPosition = '东侧'
      } else if (temporary.AccessPosition === 2) {
        temporary.AccessPosition = '西侧'
      } else if (temporary.AccessPosition === 3) {
        temporary.AccessPosition = '南侧'
      } else if (temporary.AccessPosition === 4) {
        temporary.AccessPosition = '北侧'
      }

      // 安装方式
      // if (temporary.InstallationMethod === 1) {
      //   temporary.installationMethods = "混泥土基墩"
      // } else if (temporary.InstallationMethod === 2) {
      //   temporary.installationMethods = "膨胀螺栓"
      // } else if (temporary.InstallationMethod === 3) {
      //   temporary.installationMethods = "混凝土+膨胀螺栓"
      // }

      // 用来存储需要保留2位小数的对象的属性名(key)
      let reserveArr = ['HerringboneGirderDiameter' ,'HerringboneBeamNorthSouth', 'HerringboneBeamEastWest', 'PurlinSpacing', 'PurlinDiameter', 'WoodWatchboardThickness', 'WoodPurlinSpacing', 'WoodPurlinDiameter', 'EastWestLength', 'NorthSouthWidth', 'EavesHigh', 'SouthSlopeWidth', 'NorthSlopeWidth', 'HouseHeight', 'GridConnectionDistance', 'RidgeHeight', 'RoofSlabThickness']

      // 属性遍历保留2位小数
      for (let k in temporary) {
        reserveArr.forEach(i=> {
          if (k === i) {
            temporary[k] = parseFloat(temporary[k].toString()).toFixed(2)
          }
        })
      }

      //安装方式 2
      if (temporary.InstallationMethod === 1) {
        temporary.installationMethods = "前支后拉"
      } else if (temporary.InstallationMethod === 2) {
        temporary.installationMethods = "挂钩"
      }

      baseInfo = {...temporary}
    } else  { // 直接预览
      let database = JSON.parse(sessionStorage.getItem('innerBuildingAgain')).obliqueRoofBasicModel
      baseInfo.EastWestLength = parseInt(sessionStorage.getItem('inpValW'))  / 1000 // 东西长度
      baseInfo.NorthSouthWidth = (sessionStorage.getItem('inpValH1')*1 + sessionStorage.getItem('inpValH2')*1)  / 1000 // 南北宽度
      baseInfo.SouthSlopeWidth = parseInt(sessionStorage.getItem('inpValH2'))  / 1000 // 南坡宽度
      baseInfo.SouthSlope = database.southSlope // 南坡坡度
      baseInfo.NorthSlopeWidth = parseInt(sessionStorage.getItem('inpValH1'))  / 1000 // 北坡宽度
      baseInfo.NorthSlope = database.northSlope // 北坡坡度
      baseInfo.EavesHigh = database.eavesHigh / 10 / 100 // 屋檐高度
      baseInfo.HouseHeight = database.houseHeight / 10 / 100 // 房屋高度
      if (database.houseFacing === 1) { // 房屋朝向
        baseInfo.HouseFacing = '正南'
      } else if (database.houseFacing === 2) {
        baseInfo.HouseFacing = '南偏东'
      } else if (database.houseFacing === 3) {
        baseInfo.HouseFacing = '南偏西'
      }
      baseInfo.FacingDegree = database.facingDegree // 朝向度数
      baseInfo.GridConnectionDistance = database.gridConnectionDistance / 10 / 100 // 距并网点距离
      baseInfo.RidgeHeight = database.ridgeHigh / 10 / 100 // 屋脊高度

      // if (database.roofingType === 1 ) { // 屋面类型
      //   baseInfo.RoofingType = '现浇板'
      // } else if (database.roofingType === 2 ){
      //   baseInfo.RoofingType = '预浇板'
      // } else if (database.roofingType === 3) {
      //   baseInfo.RoofingType = '木望板'
      // } else if (database.roofingType === 4) {
      //   baseInfo.RoofingType = '凛橼结构'
      // }
      console.log(database, baseInfo)
      // 屋面类型
      if(database.roofingType === 1) {
        baseInfo.RoofingType = '现浇板'
        baseInfo.RoofSlabThickness = database.roofSlabThickness / 10 /100 // 屋面厚度
      } else if (database.roofingType === 2) {
        baseInfo.RoofingType = '预制板'
        baseInfo.RoofSlabThickness = database.roofSlabThickness / 10 /100 // 屋面厚度
      } else if (database.roofingType === 3) {
        baseInfo.RoofingType = '木望板'
        baseInfo.WoodPurlinDiameter = database.woodPurlinDiameter / 10 / 100 // 木檩直径
        baseInfo.WoodPurlinSpacing = database.woodPurlinSpacing / 10 / 100 //木檩间距
        baseInfo.WoodWatchboardThickness = database.woodWatchboardThickness / 10 / 100 //木望板厚度
      } else if (database.roofingType === 4) {
        baseInfo.RoofingType = '檩橼结构'
        baseInfo.PurlinDiameter = database.purlinDiameter / 10 / 100 // 木檩条直径
        baseInfo.PurlinSpacing = database.purlinSpacing / 10 / 100 //木檩条间距
        baseInfo.HerringboneBeamEastWest = database.herringboneBeamEastWest / 10 / 100 // 人字梁东西跨度
        baseInfo.HerringboneBeamNorthSouth = database.herringboneBeamNorthSouth / 10 / 100 // 人字梁南北跨度
        baseInfo.HerringboneGirderDiameter = database.herringboneGirderDiameter / 10 / 100 // 人字梁主梁直径
      }

      if (database.installationMethod === 1) {
        baseInfo.installationMethods = '前支后拉'
      } else if (database.installationMethod === 2) {
        baseInfo.installationMethods = '挂钩'
      }

      // 用来存储需要保留2位小数的对象的属性名(key)
      let reserveArr = ['HerringboneGirderDiameter' ,'HerringboneBeamNorthSouth', 'HerringboneBeamEastWest', 'PurlinSpacing', 'PurlinDiameter', 'WoodWatchboardThickness', 'WoodPurlinSpacing', 'WoodPurlinDiameter', 'EastWestLength', 'NorthSouthWidth', 'EavesHigh', 'SouthSlopeWidth', 'NorthSlopeWidth', 'HouseHeight', 'GridConnectionDistance', 'RidgeHeight', 'RoofSlabThickness']


      // 属性遍历保留2位小数
      for (let k in baseInfo) {
        reserveArr.forEach(i=> {
          if (k === i) {
            baseInfo[k] = parseFloat(baseInfo[k].toString()).toFixed(2)
          }
        })
      }
    }



    const globleData = getCurrentInstance().appContext.app.config.globalProperties.$globleData
    const data = reactive({
      customer: window.customer,
      toast: null,
      timer: null,
      second: 40,
      stopEnd: false,
      canvasImg: sessionStorage.getItem('innerCanvas'),
      obj1: ''
    })
    const methodsMap = {
      cancelEvent() {
        this.$router.back(-1)
        // router.back()
      },
      getDate() {
        var datetime = new Date();
        var year = datetime.getFullYear();
        var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
        var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
        return year + '/' + month + '/' + date
      },
      save() {  // 保存
        data.stopEnd = true
        setTimeout(()=> {
          data.stopEnd = false
        }, 4000)
        const obj1 = JSON.parse(sessionStorage.getItem('projectAddRequest'))
        obj1.RoofType = 3
        obj1.draftImages = sessionStorage.getItem('grassImg')
        let aftData = JSON.parse(sessionStorage.getItem('requestInnerRoof'))
        let innerRoofObj = {}
        if (!aftData.isTrue) { // 直接预览保存(已有用户信息)
          console.log('直接预览保存')
          aftData = JSON.parse(sessionStorage.getItem('innerBuildingAgain')).obliqueRoofBasicModel
          innerRoofObj.roofType = aftData.roofType // 房屋类型
          innerRoofObj.houseHeight = aftData.houseHeight // 房屋高度
          if (aftData.houseFacing === 1) {
            innerRoofObj.houseFacing = 2 // 房屋朝向
          } else if (aftData.houseFacing === 2) {
            innerRoofObj.houseFacing = 3
          } else if (aftData.houseFacing === 3) {
            innerRoofObj.houseFacing = 1
          }


          innerRoofObj.facingDegree = parseInt(aftData.facingDegree) // 朝向度数
          innerRoofObj.gridConnectionDistance = aftData.gridConnectionDistance // 距并网点距离
          innerRoofObj.ridgeHigh = aftData.ridgeHigh // 屋脊高度
          // innerRoofObj.roofingType = aftData.roofingType // 屋面类型

          if (aftData.roofingType === '现浇板') { // 屋面类型
            innerRoofObj.roofingType = 1
            innerRoofObj.roofSlabThickness = aftData.roofingType // 屋面厚度
          } else if (aftData.roofingType === '预制板') {
            innerRoofObj.roofingType = 2
            innerRoofObj.roofSlabThickness = aftData.roofingType // 屋面厚度
          } else if (aftData.roofingType === '木望板') {
            innerRoofObj.roofingType = 3
            innerRoofObj.woodPurlinDiameter = aftData.woodPurlinDiameter // 木檩直径
            innerRoofObj.woodPurlinSpacing =aftData.woodPurlinSpacing // 木檩间距
            innerRoofObj.woodWatchboardThickness = aftData.woodWatchboardThickness // 木望板厚度
          } else if (aftData.roofingType === '凛橼结构') {
            innerRoofObj.roofingType = 4
            innerRoofObj.purlinDiameter = aftData.purlinDiameter // 木檩条直径
            innerRoofObj.purlinSpacing = aftData.purlinSpacing // 木檩条间距
            innerRoofObj.herringboneBeamEastWest = aftData.herringboneBeamEastWest // 人字梁东西跨度
            innerRoofObj.herringboneBeamNorthSouth = aftData.herringboneBeamNorthSouth // 人字梁南北跨度
            innerRoofObj.herringboneGirderDiameter = aftData.herringboneGirderDiameter // 人字梁主梁直径
          } else {
            innerRoofObj.roofingType = aftData.roofingType // 屋面类型
            if (aftData.roofingType === 1 || aftData.roofingType === 2) {
              innerRoofObj.roofSlabThickness = aftData.roofSlabThickness // 屋面厚度
            } else if (aftData.roofingType === 3) {
              innerRoofObj.woodPurlinDiameter = aftData.woodPurlinDiameter // 木檩直径
              innerRoofObj.woodPurlinSpacing =aftData.woodPurlinSpacing // 木檩间距
              innerRoofObj.woodWatchboardThickness = aftData.woodWatchboardThickness // 木望板厚度
            } else if (aftData.roofingType ===4) {
              innerRoofObj.purlinDiameter = aftData.purlinDiameter // 木檩条直径
              innerRoofObj.purlinSpacing = aftData.purlinSpacing // 木檩条间距
              innerRoofObj.herringboneBeamEastWest = aftData.herringboneBeamEastWest // 人字梁东西跨度
              innerRoofObj.herringboneBeamNorthSouth = aftData.herringboneBeamNorthSouth // 人字梁南北跨度
              innerRoofObj.herringboneGirderDiameter = aftData.herringboneGirderDiameter // 人字梁主梁直径
            }
          }

          innerRoofObj.installationMethod = aftData.installationMethod // 安装方式
          innerRoofObj.eastWestLength = parseInt(sessionStorage.getItem('inpValW'))  // 东西长度
          innerRoofObj.northSouthWidth = (parseInt(sessionStorage.getItem('inpValH1')) + parseInt(sessionStorage.getItem('inpValH2'))) // 南北宽度
          innerRoofObj.southSlopeWidth = parseInt(sessionStorage.getItem('inpValH2'))  // 南坡宽度
          innerRoofObj.southSlope = aftData.southSlope // 南坡坡度
          innerRoofObj.northSlopeWidth = parseInt(sessionStorage.getItem('inpValH1'))  // 南坡宽度
          innerRoofObj.northSlope = aftData.northSlope // 北坡坡度
          innerRoofObj.eavesHigh = aftData.eavesHigh // 屋檐高度
        } else { // 基本信息后保存(已有用户信息)
          console.log('修改基本信息后保存')
          console.log(aftData)
          innerRoofObj.roofType = aftData.roofType // 房屋类型
          innerRoofObj.houseHeight = aftData.HouseHeight // 房屋高度
          // innerRoofObj.houseFacing = aftData.HouseFacing // 房屋朝向

          if (aftData.HouseFacing === 1) {
            innerRoofObj.houseFacing = 2 // 房屋朝向
          } else if (aftData.HouseFacing === 2) {
            innerRoofObj.houseFacing = 3
          } else if (aftData.HouseFacing === 3) {
            innerRoofObj.houseFacing = 1
          }

          innerRoofObj.facingDegree = parseInt(aftData.FacingDegree) // 朝向度数
          innerRoofObj.gridConnectionDistance = aftData.GridConnectionDistance // 距并网点距离
          innerRoofObj.ridgeHigh = aftData.RidgeHeight // 屋脊高度

          if (aftData.RoofingType === '现浇板') { // 屋面类型
            innerRoofObj.roofingType = 1
            innerRoofObj.roofSlabThickness = aftData.RoofSlabThickness * 10 * 100 // 屋面厚度
          } else if (aftData.RoofingType === '预制板') {
            innerRoofObj.roofingType = 2
            innerRoofObj.roofSlabThickness = aftData.RoofSlabThickness * 10 * 100 // 屋面厚度
          } else if (aftData.RoofingType === '木望板') {
            innerRoofObj.roofingType = 3
            innerRoofObj.woodPurlinDiameter = aftData.WoodPurlinDiameter * 10 * 100 // 木檩直径
            innerRoofObj.woodPurlinSpacing =aftData.WoodPurlinSpacing * 10 * 100 // 木檩间距
            innerRoofObj.woodWatchboardThickness = aftData.WoodWatchboardThickness * 10 * 100 // 木望板厚度
          } else if (aftData.RoofingType === '凛橼结构') {
            innerRoofObj.roofingType = 4
            innerRoofObj.purlinDiameter = aftData.PurlinDiameter * 10 * 100 // 木檩条直径
            innerRoofObj.purlinSpacing = aftData.PurlinSpacing * 10 * 100 // 木檩条间距
            innerRoofObj.herringboneBeamEastWest = aftData.HerringboneBeamEastWest * 10 * 100 // 人字梁东西跨度
            innerRoofObj.herringboneBeamNorthSouth = aftData.HerringboneBeamNorthSouth * 10 * 100 // 人字梁南北跨度
            innerRoofObj.herringboneGirderDiameter = aftData.HerringboneGirderDiameter * 10 * 100 // 人字梁主梁直径
          } else {
            innerRoofObj.roofingType = aftData.RoofingType // 屋面类型
            if (aftData.RoofingType === 1 || aftData.RoofingType === 2) {
              innerRoofObj.roofSlabThickness = aftData.RoofSlabThickness // 屋面厚度
            } else if (aftData.RoofingType === 3) {
              innerRoofObj.woodPurlinDiameter = aftData.WoodPurlinDiameter // 木檩直径
              innerRoofObj.woodPurlinSpacing =aftData.WoodPurlinSpacing // 木檩间距
              innerRoofObj.woodWatchboardThickness = aftData.WoodWatchboardThickness // 木望板厚度
            } else if (aftData.RoofingType ===4) {
              innerRoofObj.purlinDiameter = aftData.PurlinDiameter // 木檩条直径
              innerRoofObj.purlinSpacing = aftData.PurlinSpacing // 木檩条间距
              innerRoofObj.herringboneBeamEastWest = aftData.HerringboneBeamEastWest // 人字梁东西跨度
              innerRoofObj.herringboneBeamNorthSouth = aftData.HerringboneBeamNorthSouth // 人字梁南北跨度
              innerRoofObj.herringboneGirderDiameter = aftData.HerringboneGirderDiameter // 人字梁主梁直径
            }
          }

          innerRoofObj.installationMethod = aftData.InstallationMethod // 安装方式
          innerRoofObj.eastWestLength = parseInt(sessionStorage.getItem('inpValW'))   // 东西长度
          innerRoofObj.northSouthWidth = (parseInt(sessionStorage.getItem('inpValH1')) + parseInt(sessionStorage.getItem('inpValH2')))   // 南北宽度
          innerRoofObj.southSlopeWidth = parseInt(sessionStorage.getItem('inpValH2'))    // 南坡宽度
          innerRoofObj.southSlope = aftData.SouthSlope // 南坡坡度
          innerRoofObj.northSlopeWidth = parseInt(sessionStorage.getItem('inpValH1'))   // 北坡宽度
          innerRoofObj.northSlope = aftData.NorthSlope // 北坡坡度
          innerRoofObj.eavesHigh = aftData.EavesHigh // 屋檐高度

        }


        const projectJson = {
          projectAddRequest: obj1,
          requestObliqueRoof: innerRoofObj,
          SurveyTime: methodsMap.getDate(),
          ...globleData
        }

        console.log(projectJson, '上传参数')
        AddObliqueRoof(projectJson).then(res => { // 添加内厦屋顶的踏勘表单数据给后台
          console.log(res, '回传数据')
          if (res.data.success) {
            Dialog.alert({
              message: '保存成功'
            }).then(() => {
              router.push({name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery'))})
              sessionStorage.removeItem('inpValW')
              sessionStorage.removeItem('inpValH1')
              sessionStorage.removeItem('inpValH2')
            })
          }
        })

      },


      // 内厦屋顶上传
      // submit() {
      //   // 上传屋顶数据
      //   data.stopEnd = true
      //   setTimeout(()=> {
      //     data.stopEnd = false
      //   }, 4000)
      //   sessionStorage.removeItem('OinpValW')
      //   sessionStorage.removeItem('OinpValH')
      //   const obj1 = JSON.parse(sessionStorage.getItem('projectAddRequest'))
      //   obj1.draftImages = sessionStorage.getItem('grassImg')
      //
      //   let aftData = JSON.parse(sessionStorage.getItem('requestInnerRoof'))
      //   let innerRoofObj = {}
      //   // for (let i in aftData) {
      //   //   if (i === 'EastWestLength' && i )
      //   // }
      //   console.log(aftData, '内厦屋顶信息')
      //   console.log(aftData.roofType, '房屋类型')
      //   console.log(aftData.RoofingType, '屋面类型')
      //   innerRoofObj.roofType = aftData.roofType // 房屋类型
      //   innerRoofObj.houseHeight = aftData.HouseHeight // 房屋高度
      //   innerRoofObj.houseFacing = aftData.HouseFacing // 房屋朝向
      //   innerRoofObj.facingDegree = parseInt(aftData.FacingDegree) // 朝向度数
      //   innerRoofObj.gridConnectionDistance = aftData.GridConnectionDistance // 距并网点距离
      //   innerRoofObj.ridgeHigh = aftData.RidgeHeight // 屋脊高度
      //   if (aftData.RoofingType === '现浇板') { // 屋面类型
      //     innerRoofObj.roofingType = 1
      //   } else if (aftData.RoofingType === '预制板') {
      //     innerRoofObj.roofingType = 2
      //     console.log(innerRoofObj.roofingType, '预制板')
      //   } else if (aftData.RoofingType === '木望板') {
      //     console.log(3)
      //     innerRoofObj.roofingType = 3
      //   } else if (aftData.RoofingType === '凛橼结构') {
      //     console.log(4)
      //     innerRoofObj.roofingType = 4
      //   }
      //   innerRoofObj.installationMethod = aftData.InstallationMethod // 安装方式
      //   innerRoofObj.eastWestLength = aftData.EastWestLength // 东西长度
      //   innerRoofObj.northSouthWidth = aftData.NorthSouthWidth // 南北宽度
      //   innerRoofObj.southSlopeWidth = aftData.SouthSlopeWidth // 南坡宽度
      //   innerRoofObj.southSlope = aftData.SouthSlope // 南坡坡度
      //   innerRoofObj.northSlopeWidth = aftData.NorthSlopeWidth // 北坡宽度
      //   innerRoofObj.northSlope = aftData.NorthSlope // 北坡坡度
      //   innerRoofObj.eavesHigh = aftData.EavesHigh // 屋檐高度
      //
      //   const projectJson = {
      //     projectAddRequest: obj1,
      //     requestObliqueRoof: innerRoofObj,
      //     SurveyTime: methodsMap.getDate(),
      //     ...globleData
      //   }
      //
      //   console.log(projectJson, '上传参数')
      //   console.log(JSON.stringify(projectJson));
      //   data.toast = Toast.loading({
      //     message: '处理中...',
      //     forbidClick: true,
      //     duration: 0
      //   })
      //   AddObliqueRoof(projectJson).then(res => { // 添加平屋顶的踏勘表单数据给后台
      //     if (res.data.success) {
      //       console.log('保存了')
      //       AddTask(4).then(res => { // 第一次添加出图任务
      //         if (res.data.success) {
      //           data.timer = setInterval(() => { // 出图需要一定的时间,所以加循环定时器轮询出图的状态,
      //             GetTaskState(4).then(ress => {
      //               console.log(ress, 'ress*********')
      //               if (ress.data.data.jsonStatus === 2) { // 状态不为零时表示出图任务添加成功
      //                 clearInterval(data.timer)
      //                 data.toast.clear()
      //                 Dialog.alert({
      //                   message: ress.data.message
      //                 }).then(() => {
      //                   router.push('/confirmLayout')
      //                 })
      //               } else { // 异常情况  出图任务异常处理
      //                 data.second--
      //                 if (data.second === 0) {
      //                   clearInterval(data.timer)
      //                   data.toast.clear()
      //                   ProjectErrorInfo().then(result => { // 调用这个异常接口,在前端弹出异常的内容
      //                     if (result.data.success) {
      //                       Dialog.alert({
      //                         message: result.data.data.error
      //                       }).then(() => {
      //                         router.push({ name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery')) })// 异常就返回首页
      //                       })
      //                     }
      //                   })
      //                 }
      //               }
      //             })
      //           }, 1000)
      //         } else {
      //           data.toast.clear()
      //           Dialog.alert({
      //             message: res.data.message
      //           })
      //         }
      //       })
      //     }
      //   })
      // }

      submit() {

        data.stopEnd = true
        setTimeout(()=> {
          data.stopEnd = false
        }, 4000)
        const obj1 = JSON.parse(sessionStorage.getItem('projectAddRequest'))
        obj1.RoofType  = 1
        obj1.draftImages = sessionStorage.getItem('grassImg')

        let aftData = JSON.parse(sessionStorage.getItem('requestInnerRoof'))
        let innerRoofObj = {}
        if (!aftData.isTrue) { // 直接预览提交
          aftData = JSON.parse(sessionStorage.getItem('innerBuildingAgain')).obliqueRoofBasicModel
          innerRoofObj.roofType = aftData.roofType // 房屋类型
          innerRoofObj.houseHeight = aftData.houseHeight // 房屋高度
          innerRoofObj.houseFacing = aftData.houseFacing // 房屋朝向
          innerRoofObj.facingDegree = parseInt(aftData.facingDegree) // 朝向度数
          innerRoofObj.gridConnectionDistance = aftData.gridConnectionDistance // 距并网点距离
          innerRoofObj.ridgeHigh = aftData.ridgeHeight // 屋脊高度
          // innerRoofObj.roofingType = aftData.roofingType // 屋面类型
          if (aftData.roofingType === '现浇板') { // 屋面类型
            innerRoofObj.roofingType = 1
            innerRoofObj.roofSlabThickness = aftData.roofingType // 屋面厚度
          } else if (aftData.roofingType === '预制板') {
            innerRoofObj.roofingType = 2
            innerRoofObj.roofSlabThickness = aftData.roofingType // 屋面厚度
          } else if (aftData.roofingType === '木望板') {
            innerRoofObj.roofingType = 3
            innerRoofObj.woodPurlinDiameter = aftData.woodPurlinDiameter // 木檩直径
            innerRoofObj.woodPurlinSpacing =aftData.woodPurlinSpacing // 木檩间距
            innerRoofObj.woodWatchboardThickness = aftData.woodWatchboardThickness // 木望板厚度
          } else if (aftData.roofingType === '凛橼结构') {
            innerRoofObj.roofingType = 4
            innerRoofObj.purlinDiameter = aftData.purlinDiameter // 木檩条直径
            innerRoofObj.purlinSpacing = aftData.purlinSpacing // 木檩条间距
            innerRoofObj.herringboneBeamEastWest = aftData.herringboneBeamEastWest // 人字梁东西跨度
            innerRoofObj.herringboneBeamNorthSouth = aftData.herringboneBeamNorthSouth // 人字梁南北跨度
            innerRoofObj.herringboneGirderDiameter = aftData.herringboneGirderDiameter // 人字梁主梁直径
          } else {
            innerRoofObj.roofingType = aftData.roofingType // 屋面类型
            if (aftData.roofingType === 1 || aftData.roofingType === 2) {
              innerRoofObj.roofSlabThickness = aftData.roofSlabThickness // 屋面厚度
            } else if (aftData.roofingType === 3) {
              innerRoofObj.woodPurlinDiameter = aftData.woodPurlinDiameter // 木檩直径
              innerRoofObj.woodPurlinSpacing =aftData.woodPurlinSpacing // 木檩间距
              innerRoofObj.woodWatchboardThickness = aftData.woodWatchboardThickness // 木望板厚度
            } else if (aftData.roofingType ===4) {
              innerRoofObj.purlinDiameter = aftData.purlinDiameter // 木檩条直径
              innerRoofObj.purlinSpacing = aftData.purlinSpacing // 木檩条间距
              innerRoofObj.herringboneBeamEastWest = aftData.herringboneBeamEastWest // 人字梁东西跨度
              innerRoofObj.herringboneBeamNorthSouth = aftData.herringboneBeamNorthSouth // 人字梁南北跨度
              innerRoofObj.herringboneGirderDiameter = aftData.herringboneGirderDiameter // 人字梁主梁直径
            }
          }
          innerRoofObj.installationMethod = aftData.installationMethod // 安装方式
          innerRoofObj.eastWestLength = parseInt(sessionStorage.getItem('inpValW')) // 东西长度
          innerRoofObj.northSouthWidth = parseInt(sessionStorage.getItem('inpValH2')) + parseInt(sessionStorage.getItem('inpValH1')) // 南北宽度
          innerRoofObj.southSlopeWidth = parseInt(sessionStorage.getItem('inpValH2')) // 南坡宽度
          innerRoofObj.southSlope = aftData.southSlope // 南坡坡度
          innerRoofObj.northSlopeWidth = parseInt(sessionStorage.getItem('inpValH1')) // 北坡宽度
          innerRoofObj.northSlope = aftData.northSlope // 北坡坡度
          innerRoofObj.eavesHigh = aftData.eavesHigh // 屋檐高度
        } else { // 基本信息后提交
          innerRoofObj.roofType = aftData.roofType // 房屋类型
          innerRoofObj.houseHeight = aftData.HouseHeight // 房屋高度
          innerRoofObj.houseFacing = aftData.HouseFacing // 房屋朝向
          innerRoofObj.facingDegree = parseInt(aftData.FacingDegree) // 朝向度数
          innerRoofObj.gridConnectionDistance = aftData.GridConnectionDistance  // 距并网点距离
          innerRoofObj.ridgeHigh = aftData.RidgeHeight // 屋脊高度
          // innerRoofObj.roofingType = aftData.RoofingType // 屋面类型
          if (aftData.RoofingType === '现浇板') { // 屋面类型
            innerRoofObj.roofingType = 1
            innerRoofObj.roofSlabThickness = aftData.RoofSlabThickness * 1000 // 屋面厚度
          } else if (aftData.RoofingType === '预制板') {
            innerRoofObj.roofingType = 2
            innerRoofObj.roofSlabThickness = aftData.RoofSlabThickness * 1000 // 屋面厚度
          } else if (aftData.RoofingType === '木望板') {
            innerRoofObj.roofingType = 3
            innerRoofObj.woodPurlinDiameter = aftData.woodPurlinDiameter // 木檩直径
            innerRoofObj.woodPurlinSpacing =aftData.woodPurlinSpacing // 木檩间距
            innerRoofObj.woodWatchboardThickness = aftData.woodWatchboardThickness // 木望板厚度
          } else if (aftData.RoofingType === '凛橼结构') {
            innerRoofObj.roofingType = 4
            innerRoofObj.purlinDiameter = aftData.purlinDiameter // 木檩条直径
            innerRoofObj.purlinSpacing = aftData.purlinSpacing // 木檩条间距
            innerRoofObj.herringboneBeamEastWest = aftData.herringboneBeamEastWest // 人字梁东西跨度
            innerRoofObj.herringboneBeamNorthSouth = aftData.herringboneBeamNorthSouth // 人字梁南北跨度
            innerRoofObj.herringboneGirderDiameter = aftData.herringboneGirderDiameter // 人字梁主梁直径
          } else {
            innerRoofObj.roofingType = aftData.RoofingType // 屋面类型
            if (aftData.RoofingType === 1 || aftData.roofingType === 2) {
              innerRoofObj.roofSlabThickness = aftData.roofSlabThickness // 屋面厚度
            } else if (aftData.RoofingType === 3) {
              innerRoofObj.woodPurlinDiameter = aftData.woodPurlinDiameter // 木檩直径
              innerRoofObj.woodPurlinSpacing =aftData.woodPurlinSpacing // 木檩间距
              innerRoofObj.woodWatchboardThickness = aftData.woodWatchboardThickness // 木望板厚度
            } else if (aftData.RoofingType ===4) {
              innerRoofObj.purlinDiameter = aftData.purlinDiameter // 木檩条直径
              innerRoofObj.purlinSpacing = aftData.purlinSpacing // 木檩条间距
              innerRoofObj.herringboneBeamEastWest = aftData.herringboneBeamEastWest // 人字梁东西跨度
              innerRoofObj.herringboneBeamNorthSouth = aftData.herringboneBeamNorthSouth // 人字梁南北跨度
              innerRoofObj.herringboneGirderDiameter = aftData.herringboneGirderDiameter // 人字梁主梁直径
            }
          }
          innerRoofObj.installationMethod = aftData.InstallationMethod // 安装方式
          innerRoofObj.eastWestLength = parseInt(sessionStorage.getItem('inpValW')) // 东西长度
          innerRoofObj.northSouthWidth = (parseInt(sessionStorage.getItem('inpValH2')) + parseInt(sessionStorage.getItem('inpValH1')))  // 南北宽度
          innerRoofObj.southSlopeWidth = parseInt(sessionStorage.getItem('inpValH2'))// 南坡宽度
          innerRoofObj.southSlope = aftData.SouthSlope // 南坡坡度
          innerRoofObj.northSlopeWidth = parseInt(sessionStorage.getItem('inpValH1')) // 北坡宽度
          innerRoofObj.northSlope = aftData.NorthSlope // 北坡坡度
          innerRoofObj.eavesHigh = aftData.EavesHigh // 屋檐高度
        }

        innerRoofObj.RoofType = 4

        console.log(innerRoofObj, aftData);

        const projectJson = {
          projectAddRequest: obj1,
          requestObliqueRoof: innerRoofObj,
          SurveyTime: methodsMap.getDate(),
          ...globleData
        }

        console.log(projectJson, 'projectJson')
        data.toast = Toast.loading({
          message: '处理中...',
          forbidClick: true,
          duration: 0
        })

        AddObliqueRoof(projectJson).then(res => { // 添加内厦屋顶的踏勘表单数据给后台
          if (res.data.success) {
            AddTask(4).then(res => { // 第一次添加出图任务
              if (res.data.success) {
                data.timer = setInterval(() => { // 出图需要一定的时间,所以加循环定时器轮询出图的状态,
                  GetTaskState(4).then(ress => {
                    data.timeLessen--
                    if (ress.data.data.jsonStatus === 0 || ress.data.data.jsonStatus === 1) {
                      console.log(data.timeLessen, '计时器90s倒计时---------')
                      if (data.timeLessen === 0) {
                        data.toast.clear()
                        Dialog.alert({
                          message: "出图失败,请重新尝试"
                        }).then(() => {
                          router.push({ name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery')) })
                        })
                      }
                    } else if (ress.data.data.jsonStatus === 2) {
                      clearInterval(data.timer)
                      data.toast.clear()
                      Dialog.alert({
                        message: ress.data.message
                      }).then(() => {
                        router.push('/confirmLayout')
                      })
                    } else if (ress.data.data.jsonStatus === 3) {
                      clearInterval(data.timer)
                      data.toast.clear()
                      ProjectErrorInfo().then(result => { // 调用这个异常接口,在前端弹出异常的内容
                        if (result.data.success) {
                          if (result.data === null) {
                            Dialog.alert({
                              message: '出图错误'
                            }).then(() => {
                              router.push({ name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery')) })// 异常就返回首页
                            })
                          }
                          console.log(result.data, 'resdata2222222')
                          Dialog.alert({
                            message: result.data.data.errorDetail
                          }).then(() => {
                            router.push({ name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery')) })// 异常就返回首页
                          })
                        }
                      })
                    }

                    //   if (ress.data.data.jsonStatus !== 0) { // 状态不为零时表示出图任务添加成功
                    //     clearInterval(data.timer)
                    //     data.toast.clear()
                    //     Dialog.alert({
                    //       message: ress.data.message
                    //     }).then(() => {
                    //       router.push('/confirmLayout')
                    //     })
                    //   } else { // 异常情况  出图任务异常处理
                    //     data.second--
                    //     if (data.second === 0) {
                    //       clearInterval(data.timer)
                    //       data.toast.clear()
                    //       console.log('ERROR');
                    //       ProjectErrorInfo().then(result => { // 调用这个异常接口,在前端弹出异常的内容
                    //         if (result.data.success) {
                    //           console.log(result.data, 'resdata2222222')
                    //           Dialog.alert({
                    //             message: result.data.data.error
                    //           }).then(() => {
                    //             router.push({ name: 'Home', query: JSON.parse(sessionStorage.getItem('URLquery')) })// 异常就返回首页
                    //           })
                    //         }
                    //       })
                    // }
                    // }
                  })
                }, 1000)
              } else {
                // data.toast.clear()
                Toast.clear()
                Dialog.alert({
                  message: res.data.message
                })
              }
            })
          }
        })




      }

    }
    return { ...toRefs(data), baseInfo, ...methodsMap }
  }
}
</script>
<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}
.card {
  margin: 0 auto;
  margin-top: 20px;
  padding: 16px 12px;
  width: 343px;
  box-shadow: 0px 7px 43px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background: #ffffff;
  min-height: 127px;
  .title {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    color: #1a1a1a;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(199, 201, 204, 0.4);
    .left {
      display: flex;
      align-items: center;
      img {
        width: 25px;
        height: 25px;
      }
    }
    span {
      display: inline-block;
      width: 4px;
      height: 19px;
      background: #04cc66;
    }
  }
  .content {
    margin-top: 15px;
  }
  ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    font-size: 14px;
    color: #7c8190;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    li:nth-last-child(1) {
      font-weight: bold;
      color: #1a1a1a;
    }
  }
}
.card1 {
  margin-top: 12px;
  border-radius: 0;
  width: 375px;
  padding: 12px 16px;
}
.btnBox {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  margin-top: 28px;
  .addcancel,
  .cancel,
  .determine {
    display: inline-block;
    height: 46px;
    line-height: 46px;
    text-align: center;
    border-radius: 8px;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    width: 100px;
    border-style: none;
  }
  .addcancel {
    background: #8ff77a;
  }
  .cancel {
    //width: 118px;
    border: 1px solid #04cc66;
    color: #04cc66;
  }
  .determine {
    //width: 217px;
    background: #04cc66;
  }
}
.canvasimg{
  width: 100vw;
  margin-top: 20px;
  margin-left: -16px;
  height: auto;
  background-color: #628cd4;
}
</style>
